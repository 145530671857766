<template>
  <div class="top-phone--wrapper">
    <h3 class="title">Unser Top Handy</h3>

    <p>Entdecken Sie das {{ device.name }}</p>

    <div class="top-phone--description">
      <ImageComponent
        class="top-phone--image"
        :src="device.image.url"
        :height="$breakpoint.fromDesktop() ? 300 : 200"
        :width="$breakpoint.fromDesktop() ? 152 : 101"
        :alt="device.image.description"
      />

      <div class="top-phone--details">
        <Checklist v-if="$breakpoint.fromDesktop()" class="top-phone--features">
          <li
            v-for="(feature, index) in device.features"
            :key="`feature-${index}`"
          >
            {{ feature }}
          </li>
        </Checklist>

        <div class="top-phone--price">
          <p>z.B. mit {{ device.tariffDescription }}</p>

          <Price
            :formatted-euro="device.price.installment.euro"
            :formatted-cent="device.price.installment.formattedCent"
            :is-pack="false"
            period="monatlich"
          />

          <ButtonComponent
            :href="device.trackedShopUrl"
            class="top-phone--button"
            rel="noopener noreferrer"
          >
            Zum Angebot
          </ButtonComponent>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonComponent from 'src/components/common/input/accessible_button.vue'
import Checklist from 'src/components/common/checklist/checklist.vue'
import ImageComponent from 'src/components/common/image/image.vue'
import Price from 'src/components/common/pack/components/price.vue'

export default {
  name: 'PortalTopPhoneComponent',
  components: {
    Price,
    Checklist,
    ButtonComponent,
    ImageComponent
  },
  props: {
    device: {
      type: Object,
      required: true
    }
  },
  computed: {
    payoff() {
      return (
        this.device.price.installment.euro +
        0.01 * this.device.price.installment.cent
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.top-phone--wrapper {
  @include font_normal;

  display: flex;
  flex-grow: 1;
  flex-direction: column;
  border-radius: 8px;
  padding: 20px 16px;
  background-color: var(--background-secondary);

  @include fonic {
    background-image: url('/assets/webp_images/portal/top_phone/galaxy.webp');
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: auto 50%;

    @include breakpoint($up-to-desktop) {
      background-size: auto 60%;
    }
  }

  .subtitle {
    flex-grow: 1;
    margin-bottom: 20px;
  }

  @include breakpoint($from-tablet) {
    padding: 40px 16px;
    flex-basis: 50%;
  }

  @include breakpoint($from-desktop) {
    font-size: 1.2rem;
    flex-basis: 65%;
  }
}

.top-phone--description {
  display: flex;
  flex-grow: 1;

  @include breakpoint($up-to-desktop) {
    flex-direction: column;
  }
}

.top-phone--image {
  display: flex;
  justify-content: center;
  flex-basis: 50%;

  @include breakpoint($up-to-desktop) {
    :deep(img) {
      margin-bottom: 30px;
    }
  }
}

.top-phone--details {
  display: flex;
  flex-direction: column;
}

.top-phone--features {
  flex-grow: 1;

  :deep(> li.checklist-item__outer-wrapper) {
    margin-bottom: 15px;
  }
}

.top-phone--price {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.top-phone--button {
  align-self: stretch;
  margin-top: 20px;
}
</style>
